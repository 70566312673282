import { FC } from 'react';

import { News } from '@common/clients/api';
import { ProTag } from '@web/atoms/ProTag';
import { fetchMostReadNews } from '@web/handlers/fetchMostReadNews';
import { NewsBlockItem, NewsBlockItemProps } from '@web/molecules/NewsBlockItem';
import { WidgetType } from '@web/organisms/Sidebar/types';
import { SimpleWidget } from '@web/organisms/SimpleWidget';
import { SimpleWidgetConfig } from '@web/organisms/SimpleWidget/types';

import { FetcherProps } from './types';
import { mapFetchedData } from './utils';

interface Props {
    dataList?: NewsBlockItemProps[];
    isTabbedWidget?: boolean;
}

export const widgetConfig: SimpleWidgetConfig = {
    title: 'pro.title',
    metaKey: WidgetType.PRO_NEWS,
    icon: <ProTag />,
    isTabbedWidget: true,
};

// FIXME: PB-6012 - Handle (1) Pro uitgelicht widget data-fetching and layout in sidebar
export const Widget: FC<Props> = ({ dataList, isTabbedWidget }) => {
    const widgetConfigOverrides =
        isTabbedWidget !== undefined
            ? {
                  isTabbedWidget,
                  isPremium: 'inverted',
              }
            : {};
    return (
        <SimpleWidget
            {...{
                ...widgetConfig,
                ...widgetConfigOverrides,
            }}
            renderHeader={() => (!isTabbedWidget ? widgetConfig.icon : <></>)}
            ComponentItem={NewsBlockItem}
            dataList={dataList}
        />
    );
};

export const fetcherProps: FetcherProps<News[], NewsBlockItemProps[]> = {
    fetcher: fetchMostReadNews({ functionality: 'pro' }),
    mapper: mapFetchedData('visitCount', true),
    storageKey: WidgetType.PRO_NEWS,
};
