import { Route } from './Route';

export enum SidebarSequence {
    DEFAULT = 'right-col',
    PREMIUM_HOMEPAGE = 'premium-homepage',
    RIGHT_COL_ARTICLE = 'right-col-article',
    RIGHT_COL_HOMEPAGE = 'right-col-homepage',
    RIGHT_COL_MEDIA = 'right-col-media',
    RIGHT_COL_MEDIA_INDEX = 'right-col-media-index',
    RIGHT_COL_MISC = 'right-col-misc',
    RIGHT_COL_PODCAST = 'right-col-podcast',
}

// FIXME: PB-6225 - Remove 'Route.BrightPodcast' from the sidebar sequence after the migration
// Routes not listed should fallback to the default sequence.
export const SidebarSequenceByRoute: Partial<Record<Route, SidebarSequence>> = {
    [Route.Article]: SidebarSequence.RIGHT_COL_ARTICLE,
    [Route.BrightPodcast]: SidebarSequence.RIGHT_COL_PODCAST,
    [Route.ClubOverview]: SidebarSequence.RIGHT_COL_MISC,
    [Route.Club]: SidebarSequence.RIGHT_COL_MISC,
    [Route.CompetitionOverview]: SidebarSequence.RIGHT_COL_MISC,
    [Route.Competition]: SidebarSequence.RIGHT_COL_MISC,
    [Route.Homepage]: SidebarSequence.RIGHT_COL_HOMEPAGE,
    [Route.MatchByID]: SidebarSequence.RIGHT_COL_MISC,
    [Route.Podcast]: SidebarSequence.RIGHT_COL_PODCAST,
    [Route.RssMedia]: SidebarSequence.RIGHT_COL_MEDIA,
    [Route.Search]: SidebarSequence.RIGHT_COL_HOMEPAGE,
    [Route.TransferNews]: SidebarSequence.RIGHT_COL_HOMEPAGE,
    [Route.VideoOverview]: SidebarSequence.RIGHT_COL_MEDIA,
    [Route.Premium]: SidebarSequence.PREMIUM_HOMEPAGE,
};

export const getSidebarSequenceByRoute = (route?: Route) => {
    const defaultSequence = SidebarSequence.DEFAULT;
    if (typeof route !== 'string') return defaultSequence;
    return SidebarSequenceByRoute[route] ?? defaultSequence;
};
